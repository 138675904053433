.user-dashboard-main .user-sidebar-pushable {
    min-height: 100vh;
    margin:0px;
    width: 100%;
}



.user-dashboard-main .ui.inverted.menu {
    background-color: #06bcfb;
    background-image: linear-gradient(315deg, #06bcfb 0%, #4884ee 74%);
}

.user-dashboard-main .pusher{
    width: 89.655%;
   
}







