.thingsboard-device-main .thingsboard-device-form{
    margin:0px;
    max-width: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.thingsboard-device-main .single-device-form{
    margin:5px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:600px;
   
}

.thingsboard-device-main .btn{
    margin:10px auto;
    display: block;
    
}

.thingsboard-device-main .single-device-form .form-group{
    width:80%;
    margin:5px auto;
}

.thingsboard-device-main .single-device-form .form-check{
    width:80%;
    margin:5px auto;
}


@media (max-width:550px) {
    .thingsboard-device-main .single-device-form{
       width:300px
    }


    

}