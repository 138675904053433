.login-main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(130,180,231);
    background: radial-gradient(circle, rgba(130,180,231,1) 0%, rgba(78,170,246,1) 15%, rgba(62,114,167,1) 100%);
    font-family: 'Roboto';
    
}

.login-main .login-container{
    margin:0px;
    display: flex;
    justify-content: center;
    max-width: none;
    height: 500px;
    
}

.login-main .login-container .form-main h1{
    font-weight: bold;
    margin-bottom: 45px;
    font-family: sans-serif;
}

.login-main .login-container .form-main{
    background:white;
    display:flex;
    flex-direction:column;
    justify-content:center;
    padding: 8px 16px;
    border-left:1px solid rgba(0,47,52,.2);
    box-shadow:3px 3px 5px 2px rgba(0,47,52,.5);
}

.login-main .login-container .form-main .form-group{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:8px 32px;
}

.login-main .login-container .form-main .form-group label{
    align-self: flex-start;
}

.login-main .login-container .login-image{
    height: 100%;
    width:450px;
    box-shadow:-3px 3px 5px 2px rgba(0,47,52,.5);
}

.login-main .login-container .login-image img{
    width:100%;
    height:100%;
}


